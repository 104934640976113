const I18n = require("i18n-js");
const translations = require("./translations").default;
const constants = require("./constants").default;

let slice = [].slice;
I18n.locale = window.locale || 'en';
I18n.translations = translations;

window.Base64 = require('js-base64').Base64;
window.I18n = I18n;
window.raw_translations = translations;

import Vue from 'vue'
Vue.prototype.I18n = I18n;
Vue.prototype.t = function(scope, options) {
    return I18n.t(scope, options)
};
Object.defineProperty(Vue.prototype, '$role_list', {
    get() {
        return window._role_list
    }
});
Object.defineProperty(Vue.prototype, '$raw_translations', {
    get() {
        return translations
    }
});

Object.defineProperty(Vue.prototype, '$country_list', {
    get() {
        return constants.countries
    }
});
Object.defineProperty(Vue.prototype, '$region_list', {
    get() {
        return constants.regions
    }
});
Object.defineProperty(Vue.prototype, '$locale_list', {
    get() {
        return constants.locales
    }
});
Object.defineProperty(Vue.prototype, '$timezone_list', {
    get() {
        return constants.timezones
    }
});

const DateHelper = {
    // Takes the format of "Jan 15, 2007 15:45:00 GMT" and converts it to a relative time
    // Ruby strftime: %b %d, %Y %H:%M:%S GMT
    time_ago_in_words_with_parsing: function(from) {
        let date = new Date;
        date.setTime(Date.parse(from));
        return this.time_ago_in_words(date);
    },
    // Takes a timestamp and converts it to a relative time
    // DateHelper.time_ago_in_words(1331079503000)
    time_ago_in_words: function(from) {
        return this.distance_of_time_in_words(new Date, from);
    },

    distance_of_time_in_words: function(to, from = 0) {
        let distance_in_seconds = ((to - from) / 1000);
        let distance_in_minutes = Math.floor(distance_in_seconds / 60);
        let tense = distance_in_seconds < 0 ? " from now" : " ago";
        distance_in_minutes = Math.abs(distance_in_minutes);
        if (distance_in_minutes == 0) { return 'less than a minute'+tense; }
        if (distance_in_minutes == 1) { return 'a minute'+tense; }
        if (distance_in_minutes < 45) { return distance_in_minutes + ' minutes'+tense; }
        if (distance_in_minutes < 90) { return 'about an hour'+tense; }
        if (distance_in_minutes < 1440) { return 'about ' + Math.floor(distance_in_minutes / 60) + ' hours'+tense; }
        if (distance_in_minutes < 2880) { return 'a day'+tense; }
        if (distance_in_minutes < 43200) { return Math.floor(distance_in_minutes / 1440) + ' days'+tense; }
        if (distance_in_minutes < 86400) { return 'about a month'+tense; }
        if (distance_in_minutes < 525960) { return Math.floor(distance_in_minutes / 43200) + ' months'+tense; }
        if (distance_in_minutes < 1051199) { return 'about a year'+tense; }

        return 'over ' + Math.floor(distance_in_minutes / 525960) + ' years';
    }
};
Vue.prototype.distance_of_time_in_words = DateHelper.distance_of_time_in_words;
Vue.prototype.time_ago_in_words = DateHelper.time_ago_in_words;
export default I18n