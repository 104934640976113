// Cookie support MOVE TO NETWORK PACK
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import axios from 'axios';
import Qs from 'qs';

const moment = require('moment');
const VueCookie = require('vue-cookie');
// Tell Vue to use the plugin
Vue.use(VueCookie);
// setup action cable
import ActionCable from '../src/internal_libs/uni_cable/uni_cable';
window.ActionCable = ActionCable;
// register the reference to the vue prototype
Vue.prototype.$ActionCable = ActionCable;

// Medusa Mixin, provides a hook to the global XHR object, so we can read medusa skin changes on any request.
window.serverClockOffset = 0;
(function (WS) {
    "use strict";
    // any websocket hooks you want.
})(WebSocket);

const data = {
    correctional: false,
    offset: 0,
    locale: 'en',
    mobile_ux: false,
    mobile_ulx: false,
    bandwidth: 1.7,
    output_mode: 720,
    ramLimit: false
};
moment.updateLocale('en', {
    invalidDate : 'Never'
});
moment.updateLocale('es', {
    invalidDate : 'Nunca'
});

Vue.util.defineReactive(data, 'correctional', false);
Object.defineProperty(Vue.prototype, '$correctional', {
    get() {
        return data.correctional;
    },
    set(x) {
        data.correctional = x;
    }
});

Vue.util.defineReactive(data, 'ramLimit', false);
Object.defineProperty(Vue.prototype, '$ramLimit', {
    get() {
        return data.ramLimit;
    },
    set(x) {
        data.ramLimit = x;
    }
});


Object.defineProperty(Vue.prototype, '$serverClockOffset', {
    get() {
        return data.offset;
    },
    set(x) {
        data.offset = x;
    }
});
Vue.util.defineReactive(data,'active_locale','en');
Object.defineProperty(Vue.prototype, '$active_locale', {
    get() {
        return data.locale;
    },
    set(x) {
        Vue.prototype.I18n.locale = x;
        moment.locale(x);
        data.locale = x;
    }
});
Vue.util.defineReactive(data,'mobile_ux',false);
Object.defineProperty(Vue.prototype, '$mobile_ux', {
    get() {
        return data.mobile_ux;
    },
    set(x) {
        if(data.mobile_ux != x)
            data.mobile_ux = x;
    }
});
Vue.util.defineReactive(data,'mobile_ulx',false);
Object.defineProperty(Vue.prototype, '$mobile_ulx', {
    get() {
        return data.mobile_ulx;
    },
    set(x) {
        if(data.mobile_ulx != x)
            data.mobile_ulx = x;
    }
});

Vue.util.defineReactive(data,'bandwidth',1.7);
Object.defineProperty(Vue.prototype, '$bandwidth', {
    get() {
        return data.bandwidth;
    },
    set(x) {
        if(data.bandwidth != x)
            data.bandwidth = x;
    }
});

Vue.util.defineReactive(data,'output_mode',720);
Object.defineProperty(Vue.prototype, '$output_mode', {
    get() {
        return data.output_mode;
    },
    set(x) {
        if(data.output_mode != x)
            data.output_mode = x;
    }
});

const csrfToken = function() {
    var meta;
    meta = document.querySelector('meta[name=csrf-token]');
    return meta && meta.content;
};

const csrfParam = function() {
    var meta;
    meta = document.querySelector('meta[name=csrf-param]');
    return meta && meta.content;
};

const CSRFProtection = function(request) {
    var token;
    token = csrfToken();
    if (token !== null) {
        request.headers.common['X-CSRF-Token'] = token;
        return token;
    }
};
const refreshCSRFTokens = function() {
    var param, token;
    token = csrfToken();
    param = csrfParam();
    if ((token !== null) && (param !== null)) {
        window.jQuery('form input[name="' + param + '"]').forEach(function(input) {
            input.value = token;
        });
    }
};
const isHandlerEnabled = (config={}) => {
    return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ?
        false : true
}
axios.interceptors.request.use(function (request) {
    if (isHandlerEnabled(request)) {
        CSRFProtection(request);
        request.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        request.headers.common['X-Aztec'] = '1';
        request.headers.common['X-Vue'] = '1';
        request.headers.common['Worker-Type'] = 'zeus';
        request.headers.common['Accept'] = "application/json, text/javascript, */*; q=0.01";
        request.headers.put['ContentType'] = "application/x-www-form-urlencoded; charset=UTF-8";
        request.headers.patch['ContentType'] = "application/x-www-form-urlencoded; charset=UTF-8";
    }
    return request;
});
axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = function (params) {
    return Qs.stringify(params, {arrayFormat: 'brackets'})
};
let in_reloading_state = false;
axios.interceptors.response.use(function(response){ // intercept the global error
    let callbackHook = new Promise(function(resolve, reject) {
        Vue.prototype.scry();
        Vue.prototype.scry_2(response);
        resolve(response);
        Vue.prototype.$flash.dataIdle();
    });
    //return response
    return callbackHook;
}, function (error) {
    if (isHandlerEnabled(error.config)){
        if(axios.isCancel(error)) {
            return Promise.reject(error);
        }
        Vue.prototype.$flash.dataIdle();
        if(error.response) {
            console.log("network error hit:",error.response);
        }
        if(error.response && error.response.status == 503) {
            console.log("503 error hit",error.response)
            let rsp = window.I18n.t('a11y.server_busy');
            if(in_reloading_state == false) {
                in_reloading_state = true;
                Vue.prototype.$flash.error(rsp);
                if (Vue.prototype.$event_bus) {
                    Vue.prototype.$event_bus.$emit('terminate');
                    Vue.prototype.$event_bus.$emit('cleanup');
                }
                setTimeout(() => {
                    console.log("Redirect out via service gone")
                    if (window._current_user) {
                        window.location = '/maintenance';
                        //window.location.reload(true);
                    }
                }, 10);
            }
            return Promise.reject(error.response)
        }
        if(error.response && error.response.status == 420) {
            document.body.innerText = error.response.data;
            return Promise.reject(error.response)
        }
        if (error.response && error.response.status == 401) { // unauthorized
            console.log("Issuing redirect!",JSON.stringify(error));
            let rsp = window.I18n.t('a11y.network_error_reload');
            if(typeof(error.response.data) == 'string')
                rsp = error.response.data;
            if(in_reloading_state == false) {
                in_reloading_state = true;
                Vue.prototype.$flash.error(rsp);
                if (Vue.prototype.$event_bus) {
                    Vue.prototype.$event_bus.$emit('terminate');
                    Vue.prototype.$event_bus.$emit('cleanup');
                }
                setTimeout(() => {
                    console.log("Redirect out via reload")
                    if (window._current_user) {
                        window.location = '/users/sign_out';
                        //window.location.reload(true);
                    }
                }, 10);
            }
        }
    }
    return Promise.reject(error.response);
});
Vue.prototype.scry_2= function(axios_response) {
    if(axios_response && axios_response.headers && axios_response.headers['x-skin']) {
        let skinValue = axios_response.headers['x-skin'];
        if(skinValue && skinValue.length > 1) {
            if (window['Medusa']) {
                window['Medusa'].activateSkin(skinValue);
            }
        }
    }
}
Vue.prototype.scry= function(obj) {
        let isCorrectional = this.$cookie.get('_crm');
        if (isCorrectional && isCorrectional == 'true' && !this.$correctional) {
            this.$correctional = true;
            if(obj) {
                obj.$correctional = true;
            }
        }
        if (isCorrectional && isCorrectional == 'false' && this.$correctional) {
            this.$correctional = false;
            if(obj) {
                obj.$correctional = false;
            }
        }
        let skinValue = this.$cookie.get('skin');
        if (window['Medusa'] && skinValue && skinValue.length > 1) {
            window['Medusa'].activateSkin(skinValue);
        }
        let userLocale = this.$cookie.get('zlocale') || window.locale;
        if(userLocale && userLocale.length > 1 && this.$active_locale != userLocale) {
            this.$active_locale = userLocale;
            if(obj) {
                obj.$active_locale = userLocale;
            }
        }
};
Vue.prototype.getBackgroundColor = function(element_id) {
    let color = window.getComputedStyle(document.querySelector(element_id)).getPropertyValue('background-color');
    return color;
};

(function(XHR) {
    "use strict";
    var debug = false;
    var stats = [];
    var timeoutId = null;

    var open = XHR.prototype.open;
    var send = XHR.prototype.send;
    var serverTimeOffset = 0;
    var latency = 100000000000;

    XHR.prototype.open = function(method, url, async, user, pass) {
        this._url = url;
        this._method = method;
        this.clientTimestamp = Date.parse(new Date().toUTCString());
        open.call(this, method, url, async, user, pass);
    };

    XHR.prototype.send = function(data) {
        if(this._url.indexOf("bam") != -1) {
            return send.call(this,data);
        }
        let self = this;
        let start;
        let oldOnReadyStateChange;
        let oldOndownload;
        let oldOnUpload;
        let url = this._url;
        let method = this._method;
        let stimer = 0;
        let rtimer = 0;
        let gtimer = 0;
        let wait_timer = 0;
        let duration = 0;
        function onReadyStateChange() {
            if(self.readyState === 1 || self.readyState === 0) {
                if (typeof(self._url) == 'string' && self._url.indexOf('sync_clock') > 0 && self._method == 'HEAD') {
                    self.clientTimestamp = Date.parse(new Date().toUTCString());
                }
                Vue.prototype.$flash.dataWait(duration);
                stimer = setInterval(function(){Vue.prototype.$flash.dataWait(duration); duration += 1000;},1000);
            }
            if(self.readyState === 2) {
                clearInterval(stimer);
                Vue.prototype.$flash.dataSent();
                rtimer = setInterval(function(){Vue.prototype.$flash.dataSent();},1000);

                if (typeof(self._url) == 'string' && self._url.indexOf('sync_clock') > 0 && self._method == 'HEAD') {
                    let now = new Date().getTime();
                    let serverTimestampStr = parseInt(self.getResponseHeader('X-ClockSync')) * 1000;
                    let serverTimestamp = Date.parse(new Date(serverTimestampStr).toUTCString());
                    let newclientTimestamp = new Date(self.clientTimestamp - (self.clientTimestamp - now));
                    let serverClientRequestDiffTime = serverTimestamp - newclientTimestamp;
                    if(serverClientRequestDiffTime != NaN) {
                        if(window['AztecApplication']){
                            window['AztecApplication'].$serverClockOffset = serverClientRequestDiffTime;
                        }
                        if(debug) {
                            var date = new Date();
                            date.setTime(date.getTime() + window.serverClockOffset);
                        }
                    }
                }
            }
            if(self.readyState === 3) {
                clearInterval(stimer);
                clearInterval(rtimer);
            }
            if(self.readyState === 4 /* complete */) {
                Vue.prototype.$flash.dataSent();
                Vue.prototype.$flash.dataReceived();
                if(self._url.indexOf('bam') == -1)
                    Vue.prototype.$flash.dataBusy();
                clearInterval(stimer);
                clearInterval(rtimer);
                if(debug) {
                    let g = new Date();
                    let time = g.getTime() - start.getTime();
                    stats.push({
                        url: url,
                        method: method,
                        duration: time,
                        data: data,
                        result: JSON.stringify(self.response)
                    });
                    if (!timeoutId) {
                        timeoutId = window.setTimeout(function () {
                            console.log("DEBUG XHR DATA:", {stats: stats});
                            timeoutId = null;
                            stats = [];
                        }, 2000);
                    }
                }
                self.clientTimestamp = null;
            }
            // crm checks
            let isCorrectional = Vue.prototype.$cookie.get('_crm');
            if (isCorrectional && isCorrectional == 'true') {
                if(window['AztecApplication']){
                    window['AztecApplication'].$correctional = true;
                }
            }
            // skin checks
            let skinValue = Vue.prototype.$cookie.get('skin');
            if(skinValue && skinValue.length > 1) {
                if(Vue.prototype.$medusa) {
                    Vue.prototype.$medusa.activateSkin(skinValue);
                }
                else if (window['Medusa']) {
                    window['Medusa'].activateSkin(skinValue);
                }
            }
            if(oldOnReadyStateChange) {
                oldOnReadyStateChange();
            }
        }
        function onUpload(ev) {
            Vue.prototype.$flash.dataSent(ev.loaded);
            if(oldOnUpload)
                oldOnUpload(ev);
        }
        function onDownload(ev) {
            Vue.prototype.$flash.dataReceived(ev.loaded);
            if(oldOndownload)
                oldOndownload(ev);
        }
        function loadstart(){
            Vue.prototype.$flash.dataWait(duration);
            wait_timer = setInterval(function(){
                duration += 1000;
                Vue.prototype.$flash.dataReceived();
                Vue.prototype.$flash.dataWait(duration);
            },1000);
        }
        function loadEnd(){
            clearInterval(wait_timer);
            duration = 0;
        }
        if(!this.noIntercept) {
            start = new Date();
            if(this.upload.addEventListener){
                this.upload.addEventListener("progress",onUpload,false);
            } else {
                oldOnUpload = this.upload.onprogress;
                this.upload.onprogress = onUpload;
            }
            if(this.addEventListener) {
                // how do we ask to be notified last of the event so we can show the busy indicator
                this.addEventListener("readystatechange", onReadyStateChange, false);
                this.addEventListener("progress",onDownload,false);
                this.addEventListener('loadstart',loadstart,false);
                this.addEventListener('loadend',loadEnd,false);
            } else {
                oldOnReadyStateChange = this.onreadystatechange;
                oldOndownload = this.onprogress;
                this.onreadystatechange = onReadyStateChange;
                this.onprogress = onDownload;
            }
        }
        if (typeof(this._url) == 'string' && this._url.indexOf('olympus') > 0 && self._method == 'HEAD') {
            this.clientTimestamp =  Date.parse(new Date().toUTCString());
        }
        send.call(this, data);
    }
})(XMLHttpRequest);
